/* eslint-disable max-len */
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameApiService } from '@dc-api/game.api.service';
import { Checkout121, User } from '@dc-core/dc-backend/dc-classes';
import { ReportOrigin } from '@dc-core/dc-backend/dc-enums';
import { AlertPayload } from '@dc-core/dc-services/alert.service';
import { ModalController, NavController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { RouterEventsService } from '@services/router-events.service';
import moment from 'moment';
import { take } from 'rxjs';
import { ProfileDialogComponent } from 'src/app/modules/profile/dialogs/profile-dialog/profile.dialog';
import { LoadingService } from 'src/app/services/loading.service';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { ReportUserDialogComponent } from 'src/dialogs/report-user/report-user.dialog';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-checkout-121-details',
    templateUrl: 'checkout-121-details.component.html',
    styleUrls: ['checkout-121-details.component.scss'],
})
export class Checkout121DetailsComponent {
    private modalController: ModalController = inject(ModalController);

    public title: string;
    public game: Checkout121;
    public rounds: { round: number; number_to_throw: number; expanded: boolean; success: boolean; turns: any[] }[] = [];

    constructor(
        public auth: AuthService,
        public nav: NavController,
        public translateService: TranslateService,
        private loading: LoadingService,
        public route: ActivatedRoute,
        private gameApiService: GameApiService,
        private _previousRouteService: RouterEventsService
    ) {
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            this.gameApiService
                .getCheckout121ById({ checkout121Id: params.gameId })
                .then((res) => {
                    this.game = res.data;
                    this.game.started_at = moment(this.game.started_at).toISOString();
                    this.rounds = this.groupGameRound(this.game);

                    setTimeout(() => {
                        this.loading.dismissLoader();
                    }, 250);
                })
                .catch(() => {
                    setTimeout(() => {
                        this.loading.dismissLoader();
                    }, 250);
                    // Error go back
                    this.nav.pop();
                });
        });
    }

    private groupGameRound(
        game: Checkout121
    ): { round: number; number_to_throw: number; expanded: boolean; success: boolean; turns: any[] }[] {
        const playerNames = game.users.reduce((acc, user) => {
            acc[user.id] = user.user?.first_name ?? user.ghost_name;
            return acc;
        }, {});

        const groupTurnsByRound = game.turns.reduce((acc, turn) => {
            const playerName = playerNames[turn.checkout_121_user_id];

            if (!acc[turn.round]) {
                acc[turn.round] = [];
            }

            acc[turn.round].push({
                ...turn,
                playerName: playerName,
            });

            return acc;
        }, {});

        return Object.keys(groupTurnsByRound).map((round) => ({
            round: Number(round),
            number_to_throw: groupTurnsByRound[round][0].number_to_throw,
            expanded: false,
            success: groupTurnsByRound[round].some((turn) => {
                return turn.total_score === turn.number_to_throw;
            }),
            turns: groupTurnsByRound[round],
        }));
    }

    getUserName(user: any): string {
        if (user.user) {
            return user.user.first_name;
        } else {
            return user.ghost_name;
        }
    }

    getAverage(avg) {
        return parseFloat(avg);
    }

    TryRemove() {
        //Show Dialog, do you want to invite your opponent to continue this match?
        let promptDialog;
        this.modalController
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@REMOVE_GAME:Remove game`,
                    text: $localize`:@@REMOVING_UNVERIFIED_GAME:Are you sure you want to delete this game from your statistics?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'red',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();

                promptDialog = elem;
                promptDialog.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        $localize`:@@REMOVING_IN_PROGRESS:We're removing the game, please wait`;
                        this.loading
                            .showCustomLoader({
                                showBackdrop: true,
                                spinner: 'dots',
                                cssClass: 'whiteText',
                                message: this.translateService.instant('REMOVING_IN_PROGRESS'),
                            })
                            .then(() => {
                                this.gameApiService
                                    .removeCheckout121s({
                                        checkout_121_ids: [this.game.id],
                                    })
                                    .then(() => {
                                        this.loading.dismissLoader();
                                        this.back(true);
                                    })
                                    .catch(() => {
                                        this.loading.dismissLoader();
                                    });
                            });
                    }
                });
            });
    }

    back(refresh: boolean): void {
        if (this._previousRouteService.getPreviousUrl()?.includes('statistics/score-training/list')) {
            this.nav.navigateBack('statistics/score-training/list', {
                queryParams: {
                    refresh,
                },
            });
        } else {
            this.nav.back();
        }
    }

    expandItem(item): void {
        item.expanded = !item.expanded;
    }

    public showPlayerInfo(user: User) {
        this.modalController
            .create({
                component: ProfileDialogComponent,
                componentProps: {
                    user,
                    canReport: this.auth.user.id != user.id,
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                backdropDismiss: true,
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data === 'report') {
                        this.modalController
                            .create({
                                component: ReportUserDialogComponent,
                                cssClass: 'auto-height',
                                componentProps: {
                                    user,
                                    origin: 'game_details' as ReportOrigin,
                                },
                            })
                            .then((elem) => {
                                elem.present();
                            });
                    }
                });
            });
    }
}
